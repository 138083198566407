// import './App.css';

import UserDetailsForm from "./pages/UserDetailsFormPage/UserDetailsForm";
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Quiz from "./pages/QuizPage/Quiz";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<UserDetailsForm/>} />
        <Route path="/quiz" element={<Quiz/>} />
      </Routes>
    </Router>
  );
}

export default App;
