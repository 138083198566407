import React, { useState } from 'react';
import './userDetailsForm.css';
import { useNavigate, Link } from 'react-router-dom';

function UserDetailsForm() {
    const [formData, setFormData] = useState({
        name: '',
        don_vi: '',
        chung_chi: '',
        so_dien_thoai: '',
        email: '',
    });
    const [fieldErrors, setFieldErrors] = useState({
        name: false,
        don_vi: false,
        chung_chi: false,
        so_dien_thoai: false,
        email: false,
    })
    // const [isSending, setIsSending] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        // Biểu thức chính quy để kiểm tra định dạng email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    
        // Validate email format if the changed field is 'email'
        const isEmailField = name === 'email';
        setFieldErrors({
            ...fieldErrors,
            [name]: isEmailField ? !validateEmail(value) : !value.trim(),
        });
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
    
        // Validate each field
        const errors = {};
        Object.keys(formData).forEach((key) => {
            const isEmailField = key === 'email';
            errors[key] = isEmailField ? !validateEmail(formData[key]) : !formData[key].trim();
        });
    
        // Update the error state
        setFieldErrors(errors);
    
        // Check if there are no errors
        if (Object.values(errors).every((error) => !error)) {
            // Proceed with your logic for form submission
            setShowSuccessPopup(true);
        }
    };
    
    

    const closeSuccessPopup = () => {
        setShowSuccessPopup(false);
    }


    return (
        <div>
            <div className="user-detail-form-container">
                <h2 className="form-title">THÔNG TIN THÍ SINH</h2>
                <form onSubmit={handleSubmit} className="custom-form">
                    <div className="form-group">
                        <label className="custom-form-label" htmlFor="name">
                            Họ và tên:
                            {/* <span className="required-label">*</span> */}
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className={`custom-form-input${fieldErrors.name ? ' error' : ''}`}
                            required
                        />
                        {fieldErrors.name && <div className="error-message">Vui lòng điền thông tin Họ và tên</div>}
                    </div>

                    <div className="form-group">
                        <label className="custom-form-label" htmlFor="but_danh">Đơn vị:</label>
                        <input
                            type="text"
                            id="don_vi"
                            name="don_vi"
                            value={formData.don_vi}
                            onChange={handleInputChange}
                            className={`custom-form-input${fieldErrors.don_vi ? ' error' : ''}`}
                            required
                        />
                        {fieldErrors.don_vi && <div className="error-message">Vui lòng điền thông tin Đơn vị</div>}
                    </div>

                    <div className="form-group">
                        <label className="custom-form-label" htmlFor="dia_chi">Chứng chỉ nghiệm vụ sư phạm:</label>
                        <input
                            type="text"
                            id="chung_chi"
                            name="chung_chi"
                            value={formData.chung_chi}
                            onChange={handleInputChange}
                            className={`custom-form-input${fieldErrors.chung_chi ? ' error' : ''}`}
                            required
                        />
                        {fieldErrors.chung_chi && <div className="error-message">Vui lòng điền thông tin Chứng chỉ nghiệm vụ sư phạm</div>}
                    </div>

                    <div className="form-group">
                        <label className="custom-form-label" htmlFor="so_dien_thoai">Số điện thoại:
                        </label>
                        <input
                            type="number"
                            id="so_dien_thoai"
                            name="so_dien_thoai"
                            value={formData.so_dien_thoai}
                            onChange={handleInputChange}
                            className={`custom-form-input${fieldErrors.so_dien_thoai ? ' error' : ''}`}
                            required
                        />
                        {fieldErrors.so_dien_thoai && <div className="error-message">Vui lòng điền thông tin Số điện thoại</div>}
                    </div>

                    <div className="form-group">
                        <label className="custom-form-label" htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className={`custom-form-input${fieldErrors.email ? ' error' : ''}`}
                            title="Vui lòng nhập địa chỉ email đúng định dạng, ví dụ: example@example.com"

                            required
                        />
                        {fieldErrors.email && <div className="error-message">Vui lòng điền thông tin Email</div>}
                    </div>

                    <button
                        type="submit"
                        className="custom-form-button"
                        onClick={handleSubmit}
                    >
                        GỬI
                    </button>
                </form>
              
                {showSuccessPopup && (
                    <div className="success-popup">
                        <div className="success-popup-content">
                            <Link
                                to="/quiz"
                                state={{ formData: formData }}
                                className='success-popup-text'
                            >
                                BẮT ĐẦU LÀM BÀI
                            </Link>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default UserDetailsForm;
