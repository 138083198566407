import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './quiz.css';

function Quiz() {
  const location = useLocation();
  const [quizData, setQuizData] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [correctAnswers, setCorrectAnswers] = useState(null);
  const [timer, setTimer] = useState(1500); // 1800 giây = 30 phút
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [essayData, setEssayData] = useState(null)
  const [submittedEssayResponses, setSubmittedEssayResponses] = useState({})
  const [showTimeUpPopup, setShowTimeUpPopup] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [isQuizShuffled, setIsQuizShuffled] = useState(false);


  const navigate = useNavigate();

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };


  useEffect(() => {
    const formData = location.state?.formData;
    // console.log('info user', formData)
  }, [location.state]);

  useEffect(() => {
    if (!isQuizShuffled) {
      fetch('https://quiz-master-backend-nvtrung30796.vercel.app/quiz')
        .then(response => response.json())
        .then(data => {
          const shuffledData = shuffleArray(data.quizzes); // Trộn thứ tự câu hỏi
          const limitedData = shuffledData.slice(0, 20); // Chọn 20 câu hỏi

          setQuizData(limitedData);
          setIsQuizShuffled(true); // Đặt biến này để chỉ trộn một lần
          // console.log('Quiz Data:', limitedData);
        })
        .catch(error => {
          console.error("There was an error fetching users:", error);
        });
    }
  }, [isQuizShuffled]);

 




  useEffect(() => {
    fetch('https://quiz-master-backend-nvtrung30796.vercel.app/essay')
      .then(response => response.json())
      .then(data => {
        setEssayData(data);
        // console.log('Essay Data:', data);
      })
      .catch(error => {
        console.error("There was an error fetching users:", error);
      });
  }, []);

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer > 0 && !isSubmitted) {
          return prevTimer - 1;
        } else {
         
          if (!isSubmitted) {
            setShowTimeUpPopup(true);
            setShowOverlay(true)

            clearInterval(countdownTimer); 
          }
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(countdownTimer);
  }, [isSubmitted]);



  const handleOptionChange = (questionIndex, optionIndex) => {
    setSelectedOptions({
      ...selectedOptions,
      [questionIndex]: optionIndex,
    });
  };

  const handleOpenSubmitConfirmation = () => {
    setConfirmSubmit(true);
    setShowOverlay(true)
  };

  const handleCloseSubmitConfirmation = () => {
    setConfirmSubmit(false);
    setShowOverlay(false)

  };


  

  const handleSubmit = async () => {
    if (isSubmitted || !quizData || !Array.isArray(quizData)) {
      console.log('Có lỗi xảy ra hoặc bạn đã nộp bài rồi!');
      return;
    }
  
    let correctCount = 0;
  
    quizData.forEach((quiz, questionIndex) => {
      const selectedOptionIndex = selectedOptions[questionIndex];
      if (
        selectedOptionIndex !== undefined &&
        quiz.Options[selectedOptionIndex].IsCorrect
      ) {
        correctCount++;
      }
    });

    setCorrectAnswers(correctCount);
    setConfirmSubmit(false);
  
    const dataToSend = {
      correctCount,
      totalQuestions: quizData.length,
      essayResponses: submittedEssayResponses,
      formData: location.state?.formData
    };
  
    try {
      // Gửi yêu cầu lưu lịch sử
      const response = await fetch('https://quiz-master-backend-nvtrung30796.vercel.app/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)
      });
  
      if (response.ok) {
        console.log('Email sent successfully');
  
          // Gọi API lưu lịch sử sau khi email đã được gửi thành công
          const historyResponse = await fetch('https://quiz-master-backend-nvtrung30796.vercel.app/api/save-to-history', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(dataToSend)
        });
  
        if (historyResponse.ok) {
          console.log('Lịch sử đã được lưu.');

          setShowSuccessPopup(true);
        } else {
          console.error('Failed to save history');
          setShowErrorPopup(true);
        }
      } else {
        console.error('Failed to send email');
        setShowErrorPopup(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setShowErrorPopup(true);
    }
  
    // Đặt lại giá trị của các state
    setSelectedOptions({});
    setSubmittedEssayResponses([]);
    setShowTimeUpPopup(false);
    setIsSubmitted(true); // Đặt biến này để báo hiệu rằng người dùng đã nộp bài
  };

  const handleEssayChange = (questionIndex, answer) => {
    setSubmittedEssayResponses(prevResponses => ({
      ...prevResponses,
      [questionIndex]: {
        question: essayData.essay[questionIndex].QuestionText,
        answer: answer, // Sử dụng answer hoặc chuỗi rỗng nếu answer là falsy
      },
    }));
  };





  return (
    <div className="quiz-container">
      <h2>Bắt đầu làm bài thi</h2>
      {timer > 0 && (
        <p className="timer-display">Thời gian còn lại: {Math.floor(timer / 60)}:{timer % 60}</p>
      )}
      {quizData && (
        <form className="quiz-form">
          <h3>I. PHẦN CÂU HỎI TRẮC NGHIỆM: </h3>
          {quizData.map((quiz, questionIndex) => (
            <div key={questionIndex} className="quiz-question">
              <h3>Câu hỏi {questionIndex + 1}: {quiz.QuestionText}</h3>
              <ul>
                {quiz.Options.map((option, optionIndex) => (
                  <li key={optionIndex} className="quiz-option">
                    <label>
                      <input
                        type="radio"
                        name={`question${questionIndex}`}
                        value={optionIndex}
                        checked={selectedOptions[questionIndex] === optionIndex}
                        onChange={() => handleOptionChange(questionIndex, optionIndex)}
                      />
                      {option.OptionText}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <h3>II. PHẦN CÂU HỎI TÌNH HUỐNG: </h3>
          <h4>Thầy/Cô viết hãy nêu cách xử lý trong các tình huống sau (Dưới 3 dòng)</h4>

          {essayData && essayData.essay && essayData.essay.map((essay, questionIndex) => (
            <div key={questionIndex} className="quiz-question">
              <div>
                <h3>Câu hỏi {questionIndex + 1}: {essay.QuestionText}</h3>
                <textarea
                  className="custom-textarea"
                  value={submittedEssayResponses[questionIndex]?.answer || ''}
                  onChange={(e) => handleEssayChange(questionIndex, e.target.value)}
                />
              </div>
            </div>
          ))}

          <button
            type="button"
            className="quiz-submit-button"
            onClick={handleOpenSubmitConfirmation}
          >
            Nộp bài
          </button>
        </form>
      )}

      {showOverlay && <div className="overlay"></div>}

      {showTimeUpPopup && (
        <div className="time-up-popup">
          <p>
            Bạn đã hết thời gian làm bài. Vui lòng nhấn <strong>Xác nhận</strong> để nộp bài!
          </p>          <button className="confirm-button" onClick={handleSubmit}>Xác nhận</button>
        </div>
      )}
      {confirmSubmit && (
        <div className="submit-confirmation-popup">
          <p>Bạn có chắc chắn muốn gửi bài?</p>
          <button className="confirm-button" onClick={handleSubmit}>Xác nhận</button>
          <button className="cancel-button" onClick={handleCloseSubmitConfirmation}>Hủy</button>
        </div>
      )}

      {showSuccessPopup && (
        <div className="success-popup-mail">
          <p>Nộp bài thành công!</p>
          <button
            className="popup-button-success-mail"
            onClick={() => {
              setShowSuccessPopup(false);
              navigate('/'); // Điều hướng về trang chủ
            }}
          >
            Tiếp tục
          </button>
        </div>
      )}

      {showErrorPopup && (
        <div className="error-popup-mail">
          <p>Có lỗi xảy ra. Vui lòng thử lại sau!</p>
          <button className="popup-button-error-mail" onClick={() => setShowErrorPopup(false)}>Đóng</button>
        </div>
      )}
    </div>
  );
}

export default Quiz;
